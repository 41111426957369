<template>
    <div class="setting_wpr enable_tagging w-100" >
        <div class="capsule_elm mt-2 p-15">
            <h5 class="large">Enable Tagging</h5>
            <label :for="`enable_tagging_${index}`" class="switch_option capsule_btn pb-3 mb-1">
                <input type="checkbox" :id="`enable_tagging_${index}`" :true-value="1" :false-value="0" v-model="form.enable_tagging" hidden>
                <div><span></span></div>
            </label>
        </div>
        <template v-if="form.enable_tagging">
            <div class="form_grp p-15">
                <div class="group_item">
                    <label class="input_label">Select Action</label>
                    <div class="field_wpr" :class="{ 'has-error': errors[`action_type_${index}`] }">
                        <Field autocomplete="off" :name="`action_type_${index}`" label="action type" v-model="form.action_type" :rules="{ required: form.enable_tagging }">
                            <multiselect
                                v-model="form.action_type"
                                :options="questionActions"
                                placeholder="Select One.."
                                :searchable="true"
                                label="label"
                                value-prop="value"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage :name="`action_type_${index}`" class="text-danger" />
                </div>
            </div>
            <div class="edit_section" v-if="form.action_type == 1 || form.action_type == 2">
                <div class="form_grp m-0 p-0">
                    <div class="group_item">
                        <label class="input_label">
                            Select tag
                            <a class="option" @click="createTags = !createTags">Create a Tag</a>
                        </label>
                        <div class="field_wpr" :class="{ 'has-error': errors[`tag_${index}`] }">
                            <Field autocomplete="off" :name="`tag_${index}`" v-model="form.tags" :rules="{ required: form.enable_tagging }">
                                <multiselect
                                    v-model="form.tags"
                                    label="name"
                                    value-prop="id"
                                    :searchable="true"
                                    :group-select="true"
                                    :options="tagsGroup"
                                    :groups="true"
                                    group-label="type"
                                    group-options="tags"
                                    :group-hide-empty="true"
                                    placeholder="Select Tags"
                                    mode="tags"
                                >
                                    <template v-slot:grouplabel="{ group }">
                                        <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <span>{{ option.name }}</span>
                                    </template>
                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                        <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                            {{ option.name }}
                                            <span
                                                v-if="!disabled"
                                                class="multiselect-tag-remove"
                                                @mousedown.prevent="handleTagRemove(option, $event)"
                                            >
                                                <span class="multiselect-tag-remove-icon"></span>
                                            </span>
                                        </div>
                                    </template>
                                </multiselect>
                            </Field>
                        </div>
                        <ErrorMessage :name="`tag_${index}`" class="text-danger" />
                    </div>
                </div>
                <div v-if="createTags" class="form_grp">
                    <Form v-slot="{ errors }" ref="tag-form">
                        <div class="group_item">
                            <label class="input_label">Create a New Tag</label>
                            <div class="field_wpr has_suffix" :class="{ 'has-error': errors[`name_${index}`] }">
                                <Field autocomplete="off" type="text" v-model="tagForm.name" :name="`name_${index}`" placeholder="Enter Tag Name.." rules="required" />
                                <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                            </div>
                            <ErrorMessage :name="`name_${index}`" class="text-danger" />
                        </div>
                    </Form>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'

export default {
    data () {
        return {
            form: {
                enable_tagging: 0,
                action_type: '',
                tags: [],
            },
            questionActions:[
                { value: 1, label: 'Add Tag(s)' },
                { value: 2, label: 'Remove Tag(s)'},
            ],
            createTags: false,
            tagForm: {
                name: '',
                type: 'leads',
                uniqueId: 'bulk-action',
            },
            mounted: false,
        }
    },

    props: {
        modelValue: Object,
        errors: Object,
        index: [Number, String],
    },

    emits: ['update:modelValue'],

    computed: mapState({
        tagsGroup: state => state.tagModule.tagsGroup,
        tagLoader: state => state.tagModule.tagLoader,
    }),

    components: {
        Field,
        Form,
        ErrorMessage,
    },

    watch: {
        form: {
            handler (form) {
                const vm = this;

                if (vm.mounted) {
                    vm.$emit('update:modelValue', form)
                }
            },
            deep: true,
        }
    },

    mounted () {
        const vm = this;
        vm.mounted = false;

        if (vm.tagsGroup.length == 0) {
            vm.getTags();
        }

        vm.form = {enable_tagging: 0, action_type: '', tags: []};
        vm.form = JSON.parse(JSON.stringify(vm.modelValue));

        setTimeout(() => {
            vm.mounted = true;
        }, 1000)
    },

    methods: {
        ...mapActions({
            getTags: 'tagModule/getTags',
            createTag: 'tagModule/createTag',
        }),

        handleTagCreate () {
            const vm = this;
            const form = vm.$refs['tag-form'];

            form.validate().then(({ valid }) => {
                if (valid) {
                    vm.tagForm.setFieldError = form.setFieldError;

                    vm.createTag(vm.tagForm).then((result) => {
                        vm.createTags = false;
                        vm.tagForm.name = '';
                        vm.getTags();
                    });
                }
            });
        },
    },
}
</script>