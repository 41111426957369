<template>
    <Form @submit="handleSubmit" v-slot="{ errors }" class="form-question-setting-form">
        <div class="setting_wpr mb-5">
            <div class="form_grp mb-2">
                <div class="group_item">
                    <label class="input_label">Question Type</label>
                    <div class="field_wpr m-0">
                        <multiselect v-model="form.input_type_id" v-bind="questionType" placeholder="Select type.." @change="handleSubmitQuestion()"></multiselect>
                    </div>
                </div>
            </div>
            <div class="form_grp" v-if="form.input_type_id == 6">
                <div class="group_item">
                    <label class="input_label">Upload Instructions</label>
                    <div class="" :class="{ 'has-error': errors.question }">
                        <Field autocomplete="off" name="question" v-model="form.question" :class="{ 'has-error': errors.question }" rules="required">
                            <redactor v-model="form.question" name="question" :default-value="question.question" :reset-watcher="resetWatcher" />
                        </Field>
                    </div>
                    <ErrorMessage name="question" class="text-danger" />
                </div>
            </div>
            <div class="form_grp" v-else-if="form.input_type_id == 7">
                <div class="group_item">
                    <label class="input_label">Headline Text</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                        <Field autocomplete="off" type="textarea" name="headline" v-model="form.headline">
                            <textarea cols="10" rows="10" placeholder="Headline Text Goes Here..." v-model="form.headline"></textarea>
                        </Field>
                    </div>
                    <ErrorMessage name="headline" class="text-danger" />
                </div>
            </div>
            <div class="form_grp" v-else>
                <div class="group_item">
                    <label class="input_label">Question Text</label>
                    <div class="" :class="{ 'has-error': errors.question }">
                        <Field autocomplete="off" name="question" v-model="form.question" :class="{ 'has-error': errors.question }" rules="required">
                            <redactor v-model="form.question" name="question" :default-value="question.question" :reset-watcher="resetWatcher" :is-sub-text-plugin="false" />
                        </Field>
                    </div>
                    <ErrorMessage name="question" class="text-danger" />
                </div>
            </div>
            <div class="form_grp" v-if="form.input_type_id == 7">
                <div class="group_item">
                    <label class="input_label">Agreement Context</label>
                    <div class="" :class="{ 'has-error': errors.question }">
                        <Field autocomplete="off" name="question" v-model="form.question" :class="{ 'has-error': errors.question }" rules="required">
                            <redactor v-model="form.question" name="question" :default-value="question.question" :reset-watcher="resetWatcher" />
                        </Field>
                    </div>
                    <ErrorMessage name="question" class="text-danger" />
                </div>
            </div>
            <div class="form_grp" v-else>
                <div class="group_item">
                    <label class="input_label">Question Sub Text</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.sub_text }">
                        <Field autocomplete="off" type="textarea" name="sub_text" v-model="form.sub_text">
                            <textarea cols="10" rows="10" placeholder="Sub Text Goes Here..." v-model="form.sub_text"></textarea>
                        </Field>
                    </div>
                    <ErrorMessage name="sub_text" class="text-danger" />
                </div>
            </div>
            <div class="mb-5" v-if="form.input_type_id == 1 || form.input_type_id == 2">
                <h3 class="sub_header">Answer/Choice</h3>
                <ul class="mcq_list">
                    <template v-for="(option, o) of form.options" :key="o">
                        <li>
                            <input type="text" :placeholder="`Choice #${o+1}`" v-model="form.options[o].option">
                            <button type="button" class="pointer has ml-15"  @click="toggleOptionTagActions(o)">
                                <a class="option"><i class="fa fa-tags"></i></a>
                            </button>
                            <button type="button" class="danger pointer" @click="removeOption(o)">
                                <i class="fas fa-trash"></i>
                            </button>
                        </li>
                        <li v-if="form.options[o] && optionTagAction.includes(o)" class="option-tag-wpr">
                            <option-action-component v-model="form.options[o]" :index="`${form.id}_${o}`" :errors="errors" />
                        </li>
                    </template>
                </ul>
                <button type="button" class="add_btn ml-auto mt-2 mr-0 pointer" @click="addOption()">
                    <i class="fas fa-plus"></i> Add Choice
                </button>
            </div>
            <label :for="`question-required-${form.id}`" class="switch_option capsule_btn pb-3 mb-1">
                <h5 class="large">Required</h5>
                <input type="checkbox" :id="`question-required-${form.id}`" :true-value="1" :false-value="0" v-model="form.answer_required_yn" @change="handleSubmitQuestion()" hidden>
                <div><span></span></div>
            </label>
            <label :for="`question-initials-${form.id}`" class="switch_option capsule_btn mb-3">
                <h5 class="large">Initials</h5>
                <input type="checkbox" :id="`question-initials-${form.id}`" :true-value="1" :false-value="0" v-model="form.is_initial" @change="handleSubmitQuestion()" hidden>
                <div><span></span></div>
            </label>
        </div>
        <div class="action_wpr">
            <button :disabled="questionSaveLoader || questionDuplicateLoader" class="danger_btn pointer" type="button" @click="handleDelete()" v-if="question.id"><i class="fas fa-trash"></i></button>
            <button :disabled="questionSaveLoader || questionDuplicateLoader" class="btn cancel_btn" type="button" @click="closeAccordion()">Cancel</button>
            <button :disabled="questionSaveLoader || questionDuplicateLoader" class="btn cancel_btn" type="button" @click="handleDuplicate()" v-if="question.id"><i class="fa fa-spin fa-spinner" v-if="questionDuplicateLoader"></i> {{ questionDuplicateLoader ? 'Duplicating' : 'Duplicate' }}</button>
            <button :disabled="questionSaveLoader || questionDuplicateLoader" :id="`question-submit-btn-${questionUuid}`" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="questionSaveLoader"></i> {{ questionSaveLoader ? 'Saving' : 'Save' }}</button>
        </div>
    </Form>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'
    import { uuid } from 'vue-uuid'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import OptionActionComponent from '@/pages/form/components/OptionAction'

    export default {
        name: 'Widget Question Form',

        data () {
            return {
                resetWatcher: 0,
                form: {
                    headline: '',
                    question: '',
                    sub_text: '',
                    answer_required_yn: 1,
                    is_initial: 1,
                    input_type_id: 1,
                    options: [],
                },
                questionType: {
                    mode: 'single',
                    value: [1],
                    options: [
                        { value: 1, label: 'Multiple Choice (Only One Answer)' },
                        { value: 2, label: 'Multiple Choice (Multiple Answer)' },
                        { value: 3, label: 'Comment/Essay Box' },
                        { value: 4, label: 'True/False' },
                        { value: 5, label: 'Short Answer' },
                        { value: 6, label: 'Upload' },
                        { value: 7, label: 'Agreement' },
                    ],
                    searchable: true,
                },
                questionUuid: 'question-'+uuid.v4(),
                optionTagAction: [],
            };
        },

        props: {
            modelValue: Boolean,
            question: Object,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            OptionActionComponent,
        },

        watch: {
            modelValue () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            questionSaveLoader: state => state.formModule.questionSaveLoader,
            questionDuplicateLoader: state => state.formModule.questionDuplicateLoader,
            selectedForm: state => state.formModule.selectedForm,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                saveQuestion: 'formModule/saveQuestion',
                duplicateQuestion: 'formModule/duplicateQuestion',
                deleteQuestion: 'formModule/deleteQuestion',
            }),

            closeAccordion () {
                let allParentElement = document.querySelectorAll('.faq_list li');

                for (let i = 0; i < allParentElement.length; i++) {
                    allParentElement[i].classList.remove('open');
                }
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    id: vm.question.id,
                    form_id: vm.selectedForm.id,
                    headline: vm.question.headline ? vm.question.headline : '',
                    question: vm.question.question ? vm.question.question : '',
                    sub_text: vm.question.sub_text ? vm.question.sub_text : '',
                    answer_required_yn: vm.question.answer_required_yn ? vm.question.answer_required_yn : 0,
                    is_initial: vm.question.is_initial ? vm.question.is_initial : 0,
                    input_type_id: vm.question.input_type_id ? vm.question.input_type_id : 1,
                    options: vm.question.options ? JSON.parse(JSON.stringify(vm.question.options)) : [],
                };

                vm.tagForm = {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                };

                if ((vm.form.input_type_id == 1 || vm.form.input_type_id == 2) && vm.form.options.length == 0) {
                    vm.form.options = [{ option: '', question_id: vm.question.id }];
                }

                setTimeout(function () {
                    vm.resetWatcher += 1;
                }, 1000);
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                params.setFieldError = setFieldError;

                vm.saveQuestion(params).then((result) => {
                    if (result) {
                        vm.closeAccordion();
                    }
                });
            },

            addOption () {
                const vm = this;

                vm.form.options.push({ option: '', question_id: vm.question.id });
            },

            removeOption (index) {
                const vm = this;

                vm.form.options.splice(index, 1);
            },

            handleDelete () {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this question', 'Delete');
                      option.preConfirm = function() {
                                                          return  vm.deleteQuestion({ form_id: vm.selectedForm.id, question_id: vm.question.id }).then((result) => {
                                                                      if (result) {
                                                                          vm.closeAccordion();
                                                                      }
                                                                  });
                                                      };

                Swal.fire(option);
            },

            handleDuplicate () {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', 'You want to duplicate this question', 'Duplicate');
                      option.preConfirm = function() {
                                                          return  vm.duplicateQuestion({ form_id: vm.selectedForm.id, question_id: vm.question.id }).then((result) => {
                                                                      if (result) {
                                                                          vm.closeAccordion();
                                                                      }
                                                                  });
                                                      };

                Swal.fire(option);
            },

            handleSubmitQuestion () {
                const vm = this;
                const submitQuestion = document.getElementById(`question-submit-btn-${vm.questionUuid}`);

                submitQuestion.click();
            },

            toggleOptionTagActions (index) {
                const vm = this;

                if (vm.optionTagAction.includes(index)) {
                    const i = vm.optionTagAction.indexOf(index);

                    if (i > -1) {
                        vm.optionTagAction.splice(i, 1);
                    }
                } else {
                    vm.optionTagAction.push(index);
                }
            },
        },
    }
</script>

<style scoped>
    :deep(.form-question-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.form-question-setting-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }

    .mcq_list li {
        background: #f7f7f7;
        padding-right: 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mcq_list li input {
        background: transparent;
        padding: 12px 15px;
        font-size: 14px;
        line-height: 19px;
        width: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .mcq_list li button {
        border-left: 1px solid #d9d9d9;
        padding: 0 0 0 15px;
    }
    .danger_btn {
        background: transparent;
        color: #eb1414;
        font-weight: 300;
        border: 1px solid #ffbaba;
        height: 36px;
        padding: 0 15px;
        border-radius: 4px;
    }
    .form-question-setting-form .add_btn{
        font-size: 13px;
    }

    :deep(.ml-15) {
        margin-right: 10px;
    }

    :deep(.option-tag-wpr .p-15) {
        padding-left: 15px;
        padding-right: 15px;
    }
</style>
